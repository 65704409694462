


















import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
@Component
export default class paySuccess extends Vue {
  returnHomePage () {
    this.$router.push('/');
  }

  viewOrderDetails () {
    let rnpId = this.id;
    this.goToDetail(rnpId);
  }

  get id () {
    return this.$route.params.id;
  }

  mounted () {
    let rnpId = this.id;
    // this.$Api.regAndPay.getSubmitComplete(this.id).then(result => {
    //   if (!result.data.Success) {
    //     this.$Confirm(this.$t('Message.Message'), this.$t('Order.Fail'), () => {
    //       this.goToDetail(rnpId);
    //     }, () => {
    //       this.goToDetail(rnpId);
    //     });
    //   }
    //   if (result.data.ReturnValue.IsPayed) this.$Confirm(this.$t('Message.Message'), this.$t('Order.Paid'));
    //   else {
    //     this.$Confirm(this.$t('Message.Message'), this.$t('Order.Fail'), () => {
    //       this.goToDetail(rnpId);
    //     }, () => {
    //       this.goToDetail(rnpId);
    //     });
    //   }
    // });
  }
  goToDetail (rnpId) {
    if (!isNaN(Number(rnpId))) {
      this.$router.push({ name: 'orderDetail', params: { rnpId } });
    } else {
      this.$router.push({ name: 'regnpayResult', params: { rnpId } });
    }
  }
}
