import { render, staticRenderFns } from "./InsRAPPayFail.vue?vue&type=template&id=9530e2d4&scoped=true&"
import script from "./InsRAPPayFail.vue?vue&type=script&lang=ts&"
export * from "./InsRAPPayFail.vue?vue&type=script&lang=ts&"
import style0 from "./InsRAPPayFail.vue?vue&type=style&index=0&id=9530e2d4&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9530e2d4",
  null
  
)

export default component.exports