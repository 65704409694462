


















import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
@Component
export default class payFail extends Vue {
  returnHomePage () {
    this.$router.push('/');
  }

  viewOrderDetails () {
    let rnpId = this.id;
    this.goToDetail(rnpId);
  }

  get id () {
    return this.$route.params.id;
  }
  goToDetail (rnpId) {
    if (!isNaN(Number(rnpId))) {
      this.$router.push({ name: 'orderDetail', params: { rnpId } });
    } else {
      this.$router.push({ name: 'regnpayResult', params: { rnpId } });
    }
  }
  mounted () {}
}
